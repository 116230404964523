import * as Herz from '@rushplay/herz'
import * as React from 'react'
import * as ReactRouterDom from 'react-router-dom'
import * as ThemeUi from 'theme-ui'

export function NotFound() {
  const translate = Herz.I18n.useTranslate()

  return (
    <ThemeUi.Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        pt: [4, 5],
      }}
    >
      <ThemeUi.Image
        src="/images/404-icon.svg"
        alt="404"
        width="200"
        sx={{
          color: 'text',
        }}
      />

      <ThemeUi.Heading
        as="h1"
        sx={{fontSize: [4, 5], pb: 3}}
        textAlign="center"
      >
        {translate('not-found.title')}
      </ThemeUi.Heading>

      <ReactRouterDom.Link to="/">
        {translate('not-found.link')}
      </ReactRouterDom.Link>
    </ThemeUi.Box>
  )
}

// For @loadable/components
export default NotFound

Herz.I18n.Loader.preload(['not-found.title', 'not-found.link'], NotFound)
